import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Instance } from '../types';
import styled from '@emotion/styled';
import StakedBookIcon from '../assets/img/StakedBook.png';
import { LibraryType } from '../utils/enum';

interface Library {
  id: number;
  name: string;
}
interface GridItemProps {
  bookData: Instance;
  libraryType: LibraryType
  library?: Library;
  itemCount?: string
}

const GridItem: React.FC<GridItemProps> = ({ bookData,libraryType,library,itemCount}) => {
  const navigate = useNavigate();
  const navigateToInstance = () => {
    let url = `/view-instance/${bookData.id}`;
    
    const params = new URLSearchParams();
    if (libraryType === LibraryType.ALL_LIBRARIES) {
      params.set('allLibrary', 'true');
    }
    if (library?.id) {
      params.set('libraryId', library.id.toString());
    }
  
    navigate(`${url}?${params.toString()}`);
  };

  return (
    <StyledGridItem xs={6} item justifyContent="center">
      <StyledBox onClick={navigateToInstance}>
      {library && library.name && <StyledLibraryName>{library.name}</StyledLibraryName>}
      <ImageContainer>
          <StyledImg src={bookData.front_cover || ""} alt={bookData.title} />
          <BookCountBox>
          <BookIcon src={StakedBookIcon} alt="Book icon" />
          <BookCount>
            {libraryType === LibraryType.MY_LIBRARY 
              ? bookData.item_count 
              : itemCount}
          </BookCount>
        </BookCountBox>
        </ImageContainer>        
        <StyledTitle>{bookData.title} {bookData.volume_number && `(${bookData.volume_number})`}</StyledTitle>
        <StyledAuthor>
          {bookData.author}
          {bookData.author && bookData.published_year ? ', ' : ''}
          {bookData.published_year}
        </StyledAuthor>
      </StyledBox>
    </StyledGridItem>
  );
};

export default GridItem;

const StyledGridItem = styled(Grid)`
  text-align: -webkit-center;
  padding-bottom: 24px;
`;

const StyledBox = styled(Box)`
  width: 154px;
  text-align: left;
  cursor: pointer;
`;

const ImageContainer = styled(Box)`
  position: relative;
  width: 154px;
  height: 204px;
`;

const StyledImg = styled('img')`
  width: 100%;
  height: 100%;
  border-radius: 8px;
`;

const StyledTitle = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 26px;
  white-space: nowrap;
  max-width: 154px;
`;

const StyledLibraryName = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 154px;
  color: #6D2312;
  font-size: 16px;
  padding: 8px 0; 
`;

const StyledAuthor = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 26px;
  white-space: nowrap;
  max-width: 154px;
  color: #905656;
`;

const BookCountBox = styled(Box)`
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: #FDE8C6;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 2px 8px;
`;

const BookIcon = styled('img')`
  height: 18px;
  width: 18px;
  margin-right: 3px;
`;

const BookCount = styled(Typography)`
  color: #FFA100;
  font-size: 14px;
`;