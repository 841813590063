import React from 'react';
import { Box } from '@mui/material';
import LibraryBook from './LibraryBook';
import { LibraryNameInstance,Instance } from '../types';
import styled from 'styled-components';
import { LibraryType } from '../utils/enum';

// Union type for the two return types
type ItemType = LibraryNameInstance | Instance;

interface ItemListProps {
  items: ItemType[];
  libraryType: LibraryType;
}

const ItemList: React.FC<ItemListProps> = ({ items, libraryType }) => (
  <StyledBox>
    {libraryType === LibraryType.ALL_LIBRARIES
      ? items.map((bookData) => {
          // Check if bookData is LibraryNameInstance (has instance and library_name)
          if ((bookData as LibraryNameInstance).instance && (bookData as LibraryNameInstance).instance.id) {
            const bookInstance = (bookData as LibraryNameInstance).instance;
            return (
              <LibraryBook
                key={`${bookInstance.id}-${(bookData as LibraryNameInstance).library.id}`}
                bookData={bookInstance}
                libraryType={libraryType}
                library={(bookData as LibraryNameInstance).library}
                itemCount={(bookData as LibraryNameInstance).item_count}
              />
            );
          } else {
            return null;
          }
        })
      : items.map((bookData) => {
          // In this case, bookData is a simple Instance
          if ((bookData as Instance).id) {
            const simpleInstance = bookData as Instance;
            return (
              <LibraryBook
                key={simpleInstance.id}
                bookData={simpleInstance}
                libraryType={libraryType}
              />
            );
          } else {
            return null;
          }
        })}
  </StyledBox>
);

export default ItemList;

const StyledBox = styled(Box)`
  padding: 0 16px;
  padding-bottom: 16px;
`;
