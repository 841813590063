import {
  Backdrop,
  Box,
  CircularProgress,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  Typography,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useRef, useState } from 'react';
import { ItemData, ImageEncodedString } from '../types';
import { useSnackbar } from '../components/SnackbarProvider';
import { useAuthenticatedRequest } from '../hooks/AuthenticatedApiCallHook';
import { getOCRData } from '../api/items';
interface OCRBox {
  x: number;
  y: number;
  w: number;
  h: number;
  text: string;
}
interface ImageDimensions {
  width: number;
  height: number;
  scaleX: number;
  scaleY: number;
}

interface OcrComponentProps {
  itemData: ItemData;
  setItemData: React.Dispatch<React.SetStateAction<ItemData>>;
  imageEncodedString: ImageEncodedString;
  setShowOcrComponent: React.Dispatch<React.SetStateAction<boolean>>;
  file: File | null;
}

export default function OcrComponent({
  itemData,
  setItemData,
  imageEncodedString,
  setShowOcrComponent,
  file,
}: OcrComponentProps) {
  const [ocrBoxes, setOcrBoxes] = useState<OCRBox[]>([]);
  const [showOcrModal, setShowOcrModal] = useState(false);
  const [selectedBox, setSelectedBox] = useState<OCRBox | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imageDimensions, setImageDimensions] = useState<ImageDimensions>({
    width: 0,
    height: 0,
    scaleX: 1,
    scaleY: 1,
  });
  const imageRef = useRef<HTMLImageElement>(null);
  const authenticatedApiHook = useAuthenticatedRequest();
  const { showMessage } = useSnackbar();
  const updateImageDimensions = () => {
    if (imageRef.current) {
      const { naturalWidth, naturalHeight, width, height } = imageRef.current;
      setImageDimensions({
        width,
        height,
        scaleX: width / naturalWidth,
        scaleY: height / naturalHeight,
      });
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateImageDimensions);
    return () => window.removeEventListener('resize', updateImageDimensions);
  }, []);

  const processOcrData = async (file: File) => {
    setIsLoading(true);
    try {
      const response = await getOCRData(authenticatedApiHook, file);
      if (response.data.error) {
        showMessage('Failed to process image for OCR', 'error', 4000);
        console.error('OCR processing error:', response.data.error);
        return;
      }
      const ocrData = response.data.output;
      setOcrBoxes(ocrData);
      setShowOcrModal(true);
    } catch (error) {
      showMessage('Failed to process image for OCR', 'error', 4000);
      console.error('Error getting OCR data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (file) {
      processOcrData(file);
    }
  }, [file]);

  const drawBoundingBoxes = () => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    const img = new Image();
    img.src = imageEncodedString.front_cover;

    img.onload = () => {
      if (canvas && ctx) {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        ocrBoxes.forEach(box => {
          ctx.strokeStyle = 'red';
          ctx.lineWidth = 2;
          ctx.strokeRect(box.x, box.y, box.w, box.h);
        });
      }
    };
  };

  useEffect(() => {
    if (showOcrModal) {
      drawBoundingBoxes();
    }
  }, [showOcrModal, ocrBoxes]);

  const handleBoxClick = (box: OCRBox) => {
    setSelectedBox(box);
  };

  const handleClassification = (type: string) => {
    if (selectedBox) {
      if (type === 'title') {
        setItemData(prevData => ({
          ...prevData,
          title: itemData.title + ' ' + selectedBox.text,
        }));
      } else if (type === 'author') {
        setItemData(prevData => ({
          ...prevData,
          author_name: itemData.author_name + ' ' + selectedBox.text,
        }));
      }
      setSelectedBox(null);
      setOcrBoxes(prevBoxes => prevBoxes.filter(box => box !== selectedBox));

      if (ocrBoxes.length === 1) {
        setShowOcrModal(false);
      }
    }
  };

  const handleClose = () => {
    setShowOcrModal(false);
    setShowOcrComponent(false);
  };

  return (
    <>
      <Modal
        open={showOcrModal}
        onClose={() => handleClose()}
        aria-labelledby="ocr-modal-title"
        aria-describedby="ocr-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backdropFilter: 'blur(4px)',
        }}
      >
        <Box
          sx={{
            width: '90vw',
            maxWidth: '600px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxHeight: '90vh',
            overflow: 'auto',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography id="ocr-modal-title" variant="h6" component="h2">
              Classify Text Boxes
            </Typography>
            <IconButton onClick={() => handleClose()} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ position: 'relative', width: '100%', mb: 2 }}>
            <img
              ref={imageRef}
              src={imageEncodedString.front_cover}
              alt="Front Cover"
              style={{ width: '100%', height: 'auto' }}
              onLoad={updateImageDimensions}
            />
            {ocrBoxes.map((box, index) => (
              <Box
                key={index}
                sx={{
                  position: 'absolute',
                  left: `${box.x * imageDimensions.scaleX}px`,
                  top: `${box.y * imageDimensions.scaleY}px`,
                  width: `${box.w * imageDimensions.scaleX}px`,
                  height: `${box.h * imageDimensions.scaleY}px`,
                  border: '2px solid red',
                  cursor: 'pointer',
                }}
                onClick={() => handleBoxClick(box)}
              />
            ))}
          </Box>
          {selectedBox && (
            <Box>
              <Typography variant="body1" gutterBottom>
                Selected text: {selectedBox.text}
              </Typography>
              <RadioGroup
                aria-label="classification"
                name="classification"
                onChange={e => handleClassification(e.target.value)}
              >
                <FormControlLabel
                  value="title"
                  control={<Radio />}
                  label="Title"
                />
                <FormControlLabel
                  value="author"
                  control={<Radio />}
                  label="Author"
                />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label="Other"
                />
              </RadioGroup>
            </Box>
          )}
        </Box>
      </Modal>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: theme => theme.zIndex.drawer + 1,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
        open={isLoading}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Processing Image...
          </Typography>
        </Box>
      </Backdrop>
    </>
  );
}
