import * as React from 'react';

import { Box, Typography } from '@mui/material';

import { Instance } from '../types';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import boook from '../assets/img/StakedBook.png';
import { LibraryType } from '../utils/enum';

interface Library {
  id: number;
  name: string;
}

interface LibraryBookProps {
  bookData: Instance;
  libraryType: LibraryType;
  archived?: boolean ;
  library?: Library
  itemCount?: string
}

const LibraryBook: React.FC<LibraryBookProps> = ({ bookData,libraryType,archived= false,library,itemCount }) => {
  const navigate = useNavigate();
  const name = bookData.title || 'Unknown Book';
  const authorText = bookData.author ? `${bookData.author}` : 'Unknown Author';
  const yearText =  bookData.published_year ? `${bookData.published_year}` : '';

  const handleNavigate = () => {
    const url = `/view-instance/${bookData.id}`;
    const params = new URLSearchParams();
    if (libraryType === LibraryType.ALL_LIBRARIES) {
      params.set('allLibrary', 'true');
    }
    if (library?.id) {
      params.set('libraryId', library.id.toString());
    }
  
    navigate(`${url}?${params.toString()}`, {
      state: { archived }
    });
  };

  return (
    <StyledContainer onClick={handleNavigate}>
      <LibraryNameContainer>
      {library && library.name && <StyledLibraryName>{library.name}</StyledLibraryName>}
      <StyledBox>
        <StyledImage src={bookData.front_cover} alt={name} />
        <StyledInfo>
          <StyledTitle>{bookData.title}</StyledTitle>
          <StyledAuthor>{authorText}{yearText ? `, ${yearText}` : ''}</StyledAuthor>
        </StyledInfo>
      </StyledBox>
      </LibraryNameContainer>
      <Box sx={{backgroundColor: '#FDE8C6', borderRadius: '10px', flexDirection: 'row', display: 'flex', alignSelf: 'center', paddingInline: '8px',  paddingY: '2px'}}>
        <img src={boook} height={18} width={18} style={{paddingRight: 3, marginTop: 2}}/>
        <Typography color={'#FFA100'} fontSize={14}>{libraryType === LibraryType.MY_LIBRARY 
              ? bookData.item_count 
              : itemCount}</Typography>
      </Box>
    </StyledContainer>
  );
};

export default LibraryBook;

const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;
  cursor: pointer;
  justify-content: space-between;
`;

const LibraryNameContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`;

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: row;
`;

const StyledLibraryName = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 154px;
  color: #6D2312;
  font-size: 16px;
  padding: 8px 0; 
`;

const StyledImage = styled.img`
  height: 75px;
  width: 56.46px;
`;

const StyledInfo = styled(Box)`
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const StyledTitle = styled(Typography)`
  font-size: 16px;
  padding-bottom: 4px;
  font-weight: 500;
`;

const StyledAuthor = styled(Typography)`
  font-size: 14px;
  color: #908056;
  font-weight: 500;
`;
